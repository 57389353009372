import React, { useState, useContext, useCallback } from "react";
//Component
import PopupMessage from "./PopupMessage";
import PopupConfirm from "./PopupConfirm";
import Loading from "./Loading";
import PopupValidKey from "./PopupValidKey";
import { useSelector } from "react-redux";
import { getRealtimeData } from "../../service/firebase";
import PopupHtml from "./PopupHtml";

const PopupContext = React.createContext();
export function useDialog() {
  return useContext(PopupContext);
}

export function PopupContextProvider({ children }) {
  const [portal, setPortal] = useState(null);
  const [parentPortal, setParentPortal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingParent, setIsLoadingParent] = useState(false);

  const { user } = useSelector((state) => {
    return state;
  });

  const alert = useCallback(async (values) => {
    return new Promise((resolve) => {
      const resolveClosePopup = () => {
        if (!values.forceOpen) {
          resolve(true);
          setPortal(null);
        }
      };
      setPortal(
        <PopupMessage
          {...values}
          onConfirm={() => resolveClosePopup()}
          onReject={() => resolveClosePopup()}
        />
      );
    });
  }, []);

  const alertParent = useCallback(async (values) => {
    return new Promise((resolve) => {
      const resolveClosePopup = () => {
        resolve(true);
        setParentPortal(null);
      };
      setParentPortal(
        <PopupMessage
          {...values}
          onConfirm={() => resolveClosePopup()}
          onReject={() => resolveClosePopup()}
        />
      );
    });
  }, []);

  const confirm = useCallback(async (values) => {
    return new Promise((resolve) => {
      const resolveClosePopup = (status) => {
        resolve(status);
        setPortal(null);
      };
      setPortal(
        <PopupConfirm
          {...values}
          onConfirm={() => resolveClosePopup(true)}
          onCancle={() => resolveClosePopup(false)}
        />
      );
    });
  }, []);

  const loadingParent = useCallback((values) => {
    return new Promise((resolve) => {
      if (!isLoadingParent) {
        setIsLoadingParent(true);
        setParentPortal(<Loading />);
      }
    });
  }, []);

  const loading = useCallback((values) => {
    return new Promise((resolve) => {
      if (!isLoading) {
        setIsLoading(true);
        setPortal(<Loading />);
      }
    });
  }, []);

  const cancleParent = useCallback((values) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setParentPortal(null);
        setIsLoadingParent(false);
      }, 200);
    });
  }, []);

  const cancle = useCallback((values) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setPortal(null);
        setIsLoading(false);
      }, 200);
    });
  }, []);

  const validKey = useCallback(async (values) => {
    return new Promise((resolve) => {
      const resolveClosePopup = (status) => {
        resolve({ status: status });
        setPortal(null);
      };
      setPortal(
        <PopupValidKey
          onEnter={() => resolveClosePopup(true)}
          onClose={() => resolveClosePopup(false)}
        />
      );
    });
  }, []);

  const isAdmin = useCallback(async () => {
    if (!user?.uid) return false;

    const userProfile = await getRealtimeData(`User/${user.uid}`);
    return userProfile?.status && userProfile?.data?.role === "admin";
  }, []);

  const isHr = useCallback(async () => {
    if (!user?.uid) return false;

    const userProfile = await getRealtimeData(`User/${user.uid}`);
    return userProfile?.status && userProfile?.data?.role === "hr";
  }, []);

  const popupHtml = useCallback(async (values) => {
    return new Promise((resolve) => {
      const resolveClosePopup = () => {
        resolve(true);
        setPortal(null);
      };
      setPortal(
        <PopupHtml
          {...values}
          onConfirm={() => resolveClosePopup()}
          onReject={() => resolveClosePopup()}
        />
      );
    });
  }, []);
  return (
    <PopupContext.Provider
      value={{
        alert,
        alertParent,
        confirm,
        loading,
        loadingParent,
        cancle,
        cancleParent,
        validKey,
        isAdmin,
        isHr,
        popupHtml,
      }}
    >
      {children}
      {portal}
      {parentPortal}
    </PopupContext.Provider>
  );
}
