import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import moment from "moment";
//components
import Button from "../../../../component/Button/Button";
import PopupBackdrop from "../../../../component/Modal/PopupBackDrop";
import {
  getFirestoreDataById,
  getRealtimeData,
} from "../../../../service/firebase";
import { useDialog } from "../../../../component/Modal/Popup";
import { convertMinutesToText } from "../functions/action";
import { FaPaperclip, FaRegCalendarAlt } from "react-icons/fa";
import { ImUserTie } from "react-icons/im";

const PopupViewLeaveDetail = (props) => {
  const { onClose } = props;
  const { refData } = props;
  const dialog = useDialog();

  //state
  const [leaveDetail, setLeaveDetail] = useState(null);
  const [bossProfile, setBossProfile] = useState(null);
  const [hrProfile, setHrProfile] = useState(null);

  useEffect(() => {
    if (refData) {
      if (refData?.id) {
        downloadLeaveDetail();
      } else {
        setupPrepareData(refData);
      }
    }
  }, [refData]);

  const downloadLeaveDetail = async () => {
    console.log("downloadLeaveDetail", refData.id);
    const results = await getFirestoreDataById("TotalLeave", refData.id);
    if (!results.status) {
      dialog.alert({ message: `เกิดข้อผิดพลาด : ${results.message}` });
      return;
    }

    if (results.data) {
      console.log("downloadLeaveDetail", results.data);
      setLeaveDetail(results.data);
      if (results?.data?.bossId) {
        const boss = await renderUserProfileById(results.data.bossId);
        setBossProfile(boss);
      }
      if (results?.data?.hrId) {
        const hr = await renderUserProfileById(results.data.hrId);
        setHrProfile(hr);
      }
    }
  };

  const setupPrepareData = async (data) => {
    if (data?.bossId) {
      const boss = await renderUserProfileById(data.bossId);
      setBossProfile(boss);
    }
    if (data?.hrId) {
      const hr = await renderUserProfileById(data.hrId);
      setHrProfile(hr);
    }
    console.log(data);
    setLeaveDetail({
      ...data,
      attactment: data.file ? URL.createObjectURL(data.file) : "",
    });
  };

  const renderLeaveDayType = () => {
    if (!leaveDetail) {
      return "";
    }
    const { leaveDayType } = leaveDetail;
    if (!leaveDayType) {
      return "-";
    }
    if (leaveDayType === "full") {
      return "เต็มวัน";
    }
    if (leaveDayType === "half") {
      const { startTime, endTime, totalMinute } = leaveDetail;
      return `ช่วงเวลา (${startTime} - ${endTime}) ${convertMinutesToText(
        totalMinute
      )}`;
    }
    return "-";
  };

  const renderUserProfileById = async (userId) => {
    if (!userId) {
      return "-";
    }
    const results = await getRealtimeData(`Employee/${userId}`);
    if (!results.status) {
      return "-";
    }
    return `${results?.data?.firstname || ""} ${results?.data?.lastname || ""}`;
  };

  const handleOpenFile = () => {
    if (!leaveDetail?.attactment) {
      return;
    }
    window.open(leaveDetail?.attactment);
  };
  return (
    <PopupBackdrop>
      <View style={Styles.container}>
        <Header
          onClose={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
        <View style={{ ...Styles.flexRow, marginBottom: 10 }}>
          <FaRegCalendarAlt style={Styles.titleIcon} />
          <Text style={Styles.dateTitle}>
            วันที่ลา :{" "}
            {leaveDetail?.date
              ? moment(leaveDetail?.date.toDate()).format("DD-MM-YYYY")
              : "-"}
          </Text>
        </View>
        <View style={Styles.flexRow}>
          <View style={Styles.rowContent}>
            <Text style={Styles.subTitle}>หัวข้อ : </Text>
            <Text>
              {leaveDetail?.titleLabel ? leaveDetail?.titleLabel : "-"}
            </Text>
          </View>
          <View style={Styles.rowContent}>
            <Text style={Styles.subTitle}>ประเภทการลา : </Text>
            <Text>{renderLeaveDayType()}</Text>
          </View>
        </View>
        <Divider />
        <Text style={Styles.subTitle}>เหตุผลในการลา</Text>
        <Text>{leaveDetail?.reasonLeave ? leaveDetail?.reasonLeave : "-"}</Text>
        <Divider />
        <View style={{ ...Styles.flexRow }}>
          <FaPaperclip style={Styles.titleIcon} />
          <Text style={Styles.subTitle}>หลักฐานประกอบการลา</Text>
        </View>
        <Button
          buttonText={leaveDetail?.attactment ? "ดูไฟล์" : "ไม่มีไฟล์"}
          disable={!leaveDetail?.attactment}
          color={"primary"}
          style={{ marginTop: 10, maxWidth: 150 }}
          onClick={() => {
            handleOpenFile();
          }}
        />

        <Divider />
        <View style={{ ...Styles.flexRow }}>
          <ImUserTie style={Styles.titleIcon} />
          <Text style={Styles.subTitle}>ผู้อนุมัติ</Text>
        </View>
        <View style={{ flexDirection: "row", marginTop: 10 }}>
          <View style={Styles.rowContent}>
            <Text style={Styles.subTitle}>หัวหน้า : </Text>
            <Text>{bossProfile ? bossProfile : "-"}</Text>
          </View>
          <View style={Styles.rowContent}>
            <Text style={Styles.subTitle}>ฝ่ายบุคคล : </Text>
            <Text>{hrProfile ? hrProfile : "-"}</Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", marginTop: 10 }}>
          <View style={Styles.rowContent}>
            <Text style={Styles.subTitle}>วันยื่นใบลา : </Text>
            <Text>
              {leaveDetail?.giveDate
                ? moment(leaveDetail?.giveDate.toDate()).format("DD-MM-YYYY")
                : "-"}
            </Text>
          </View>
          <View style={Styles.rowContent}>
            <Text style={Styles.subTitle}>วันที่อนุมัติ : </Text>
            <Text>
              {" "}
              {leaveDetail?.approveDate
                ? moment(leaveDetail?.approveDate.toDate()).format("DD-MM-YYYY")
                : "-"}
            </Text>
          </View>
        </View>
      </View>
    </PopupBackdrop>
  );
};

const Header = (props) => {
  const { onClose, onEnter } = props;
  return (
    <View style={{ width: "100%" }}>
      <View style={{ flexDirection: "row" }}>
        <Text style={Styles.title}>รายละเอียดการลา</Text>
        <Button
          style={{ width: 100 }}
          buttonText={"ปิด"}
          color={"secondary"}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
      </View>
      <Divider />
    </View>
  );
};

export default PopupViewLeaveDetail;

const Styles = {
  container: {
    width: "90vw",
    height: "90vh",
    maxWidth: 800,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
    zIndex: 10,
  },
  content: {
    flex: 1,
    zIndex: 0,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    flex: 1,
  },
  rowContent: {
    flex: 0.5,
    flexDirection: "row",
    alignItems: "center",
  },
  dateTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },
  dateContainer: {
    flex: 0.5,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 0,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  titleIcon: {
    fontSize: 20,
    color: "#4652D1",
    marginRight: 10,
  },
};
