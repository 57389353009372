import { equalTo, orderByChild } from "firebase/database";
import {
  getFirestoreData,
  getFirestoreDataWithQuery,
  getRealtimeData,
  getRealtimeDataWithQuery,
} from "../../service/firebase";

export const getEnterprise = async () => {
  const predefinedOrder = ["ARM", "AMG", "AMD", "AAP", "AMT", "AAS"];
  return new Promise(async (resolve) => {
    const result = await getFirestoreDataWithQuery(
      "Enterprise",
      "status",
      true
    );
    if (!result.status) {
      resolve(result);
      return;
    }
    const list = result.data.map((item) => ({
      label: item.label,
      value: item.id,
    }));

    // Sort the list based on the predefinedOrder
    list.sort((a, b) => {
      const indexA = predefinedOrder.indexOf(a.label);
      const indexB = predefinedOrder.indexOf(b.label);
      if (indexA === -1 && indexB === -1) return 0; // Both not found
      if (indexA === -1) return 1; // a not found, move a to end
      if (indexB === -1) return -1; // b not found, move b to end
      return indexA - indexB; // Compare indices
    });

    resolve({ status: true, data: list });
  });
};

export const getBranch = async (enterpriseId) => {
  return new Promise(async (resolve) => {
    if (!enterpriseId)
      resolve({ status: false, data: [], message: "EnterpriseId is required" });

    const result = await getFirestoreDataWithQuery(
      `Enterprise/${enterpriseId}/Branch`,
      "status",
      true
    );
    if (!result.status) {
      resolve(result);
      return;
    }

    const list = result.data.map((item) => ({
      label: item.label,
      value: item.id,
    }));

    // Custom sort function
    list.sort((a, b) => {
      const isAInactive = /พ้นสภาพ/i.test(a.label);
      const isBInactive = /พ้นสภาพ/i.test(b.label);

      // If both are inactive or both are active, sort alphabetically and numerically
      if (isAInactive && isBInactive)
        return a.label.localeCompare(b.label, "en", { numeric: true });
      if (!isAInactive && !isBInactive)
        return a.label.localeCompare(b.label, "en", { numeric: true });

      // If only one is inactive, move it to the end
      return isAInactive ? 1 : -1;
    });

    resolve({ status: true, data: list });
  });
};

export const getAllBranches = async () => {
  const predefinedOrder = ["ARM", "AMG", "AMD", "AAP", "AMT", "AAS"];

  return new Promise(async (resolve) => {
    const enterpriseResult = await getFirestoreDataWithQuery(
      "Enterprise",
      "status",
      true
    );
    if (!enterpriseResult.status) {
      resolve(enterpriseResult);
      return;
    }

    // Extract and sort enterprises
    const enterprises = enterpriseResult.data.map((item) => ({
      label: item.label,
      value: item.id,
    }));
    enterprises.sort((a, b) => {
      const indexA = predefinedOrder.indexOf(a.label);
      const indexB = predefinedOrder.indexOf(b.label);
      if (indexA === -1 && indexB === -1) return 0;
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });

    let allBranches = [];
    for (const enterprise of enterprises) {
      const branchResult = await getFirestoreDataWithQuery(
        `Enterprise/${enterprise.value}/Branch`,
        "status",
        true
      );
      if (branchResult.status) {
        const branches = branchResult.data.map((item) => ({
          label: item.label,
          value: item.id,
          enterprise: enterprise.label, // Add enterprise reference
          enterpriseId: enterprise.value,
          branchId: item.id,
          ...item,
        }));
        allBranches.push(...branches);
      }
    }

    // Sort branches
    allBranches.sort((a, b) => {
      const isAInactive = /พ้นสภาพ/i.test(a.label);
      const isBInactive = /พ้นสภาพ/i.test(b.label);
      if (isAInactive && isBInactive)
        return a.label.localeCompare(b.label, "en", { numeric: true });
      if (!isAInactive && !isBInactive)
        return a.label.localeCompare(b.label, "en", { numeric: true });
      return isAInactive ? 1 : -1;
    });

    resolve({ status: true, data: allBranches });
  });
};

export const getTitleName = async () => {
  return new Promise(async (resolve) => {
    const query = [orderByChild("status"), equalTo(true)];
    const results = await getRealtimeDataWithQuery("Setting/TitleName", query);
    if (!results.status) {
      resolve(results);
    }
    const list = Object.keys(results.data).map((key) => ({
      label: results.data[key].name,
      value: key,
    }));
    resolve({
      status: true,
      data: list,
    });
  });
};

export const getLevelPosition = async () => {
  return new Promise(async (resolve) => {
    const query = [orderByChild("status"), equalTo(true)];
    const results = await getRealtimeDataWithQuery(
      "Setting/LevelPosition",
      query
    );
    if (!results.status) {
      resolve(results);
    }
    const list = Object.keys(results.data).map((key) => ({
      label: results.data[key].name,
      value: key,
      ...results.data[key],
    }));
    resolve({
      status: true,
      data: list,
    });
  });
};

export const getSection = async () => {
  return new Promise(async (resolve) => {
    const query = [orderByChild("status"), equalTo(true)];
    const results = await getRealtimeDataWithQuery("Section", query);
    if (!results.status) {
      resolve(results);
    }
    const list = Object.keys(results.data).map((key) => ({
      label: results.data[key].name,
      value: key,
    }));
    resolve({
      status: true,
      data: list,
    });
  });
};

export const getPosition = async () => {
  return new Promise(async (resolve) => {
    const query = [orderByChild("status"), equalTo(true)];
    const results = await getRealtimeDataWithQuery("Position", query);
    if (!results.status) {
      resolve(results);
    }
    const list = Object.keys(results.data).map((key) => ({
      label: results.data[key].name,
      value: key,
    }));
    resolve({
      status: true,
      data: list,
    });
  });
};

export const sortBranch = (branch) => {
  if (branch.length === 0) return branch;
  return branch.sort((a, b) => {
    const aLabel = String(a.label);
    const bLabel = String(b.label);

    const isAInactive = /พ้นสภาพ/i.test(aLabel);
    const isBInactive = /พ้นสภาพ/i.test(bLabel);

    // If both are inactive or both are active, sort alphabetically and numerically
    if (isAInactive && isBInactive)
      return aLabel.localeCompare(bLabel, "en", { numeric: true });
    if (!isAInactive && !isBInactive) {
      // Check if both strings are in English
      const isAEnglish = /^[A-Za-z]+$/.test(aLabel);
      const isBEnglish = /^[A-Za-z]+$/.test(bLabel);

      if (isAEnglish && isBEnglish) {
        return aLabel.localeCompare(bLabel);
      }
      // If 'a' is English and 'b' is not, 'a' should come first
      if (isAEnglish) {
        return -1;
      }
      // If 'b' is English and 'a' is not, 'b' should come first
      if (isBEnglish) {
        return 1;
      }
      // If both strings are not in English, sort normally
      return aLabel.localeCompare(bLabel, "en", { numeric: true });
    }

    // If only one is inactive, move it to the end
    return isAInactive ? 1 : -1;
  });
};

export const getEmployee = async (
  enterpriseId,
  branchId,
  onlyActive = true
) => {
  return new Promise(async (resolve) => {
    if (!enterpriseId || !branchId) {
      resolve({
        status: false,
        message: "not found enterprise and branch",
        data: [],
      });
    }

    const branchRes = await getBranch(enterpriseId);
    if (!branchRes.status) {
      resolve({
        status: false,
        message: "failure load branch from getEmployee",
        data: [],
      });
    }

    const queryConstraints = [
      orderByChild("enterpriseId"),
      equalTo(enterpriseId),
    ];
    const result = await getRealtimeDataWithQuery("Employee", queryConstraints);
    let emList = [];

    if (result.status) {
      const employees = result.data;
      Object.keys(employees).forEach((key) => {
        const employee = employees[key];
        if (onlyActive) {
          //กรณีเอาเฉพาะพนักงานที่ยังทำงานอยู่
          if (employee.active === "D" || !employee.status) {
            //สถานะไม่ถูกลบ
            return;
          }
        }

        if (employee.branchId === branchId || branchId === "ทั้งหมด") {
          emList.push({
            ...employee,
            id: key,
            key: key,
            value: key,
          });
        }
      });
    }
    resolve({ status: true, message: "", data: emList });
  });
};

export const getAllEmployee = async (onlyActive = true) => {
  return new Promise(async (resolve) => {
    const queryConstraints = [orderByChild("enterpriseId")];
    const result = await getRealtimeDataWithQuery("Employee", queryConstraints);
    let emList = [];

    if (result.status) {
      const employees = result.data;
      Object.keys(employees).forEach((key) => {
        const employee = employees[key];
        if (onlyActive) {
          //กรณีเอาเฉพาะพนักงานที่ยังทำงานอยู่
          if (employee.active === "D" || !employee.status) {
            //สถานะไม่ถูกลบ
            return;
          }
        }
        emList.push({
          ...employee,
          id: key,
          key: key,
          value: key,
        });
      });
    }
    resolve({ status: true, message: "", data: emList });
  });
};
