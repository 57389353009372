import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native-web";
import { Divider } from "rsuite";
import PopupBackdrop from "../../../../component/Modal/PopupBackDrop";
import Button from "../../../../component/Button/Button";
import {
  FaCalendarAlt,
  FaCalendarCheck,
  FaCalendarPlus,
  FaUserTimes,
} from "react-icons/fa";
import { HiDocumentRemove } from "react-icons/hi";
import TablePopupImportLeaveView from "./TablePopupImportLeaveView";
import {
  currentTimestampFirestore,
  getFirestoreData,
  getFirestoreDataById,
  getFirestoreDataWithMultiQuery,
  getRealtimeData,
  momentToUTCDateTimestamp,
} from "../../../../service/firebase";
import { useDialog } from "../../../../component/Modal/Popup";
import PopupViewLeaveDetail from "./PopupViewLeaveDetail";
import { Grid } from "@mui/material";
import moment from "moment";
import PopupImportLeave from "./PopupImportLeave";
import PopupImportGuarantee from "./PopupImportGuarantee";
import PopupImportLeaveWithOutPay from "./PopupImportLeaveWithOutPay";
import PopupImportBlame from "./PopupImportBlame";
import { useSelector } from "react-redux";
import TableViewLeaveRecal from "./TableViewLeaveRecal";
import { Timestamp } from "firebase/firestore";
import { convertImportLeavePattern } from "../functions/action";
import { calculateManHour } from "../functions/calculateManHour";
import uuid from "react-uuid";
import { validateFingerprintsLevel } from "../functions/validates";
import { formatNumber } from "../../../../function/numberFormat";
import PopupAbsentWork from "./PopupAbsentWork";
import { set } from "firebase/database";
import { MdBlock } from "react-icons/md";

const PopupImportLeaveView = (props) => {
  const { refId } = props;
  const { onClose, onEnter } = props;
  const dialog = useDialog();
  const { user, attendanceInit } = useSelector((state) => state);

  //state
  //list คือรายการ importLeave
  const [list, setList] = useState([]);
  const [listBackup, setListBackup] = useState([]);

  //master data ห้ามเปลี่ยนแปลง
  const [timeAttendanceMaster, setTimeAttendanceMaster] = useState({});
  const [isFirstOpenView, setIsFirstOpenView] = useState(true);
  //ใช้ทั่วไป
  const [timeAttendanceData, setTimeAttendanceData] = useState({});
  //สำหรับแสดงการคำนวนเวลา
  const [timeAttendaceRender, setTimeAttendaceRender] = useState([]);
  //สำหรับสำรองเวลาที่ เลือกขาดงาน (ใช้เฉพาะไม่มีการนำไปใช้ส่วนคำนวน)
  const [timeAttendanceBackup, setTimeAttendanceBackup] = useState({});
  const [updateAttendanceData, setUpdateAttendanceData] = useState(null);
  const [viewLeaveDetailData, setViewLeaveDetailData] = useState(null);
  const [popupViewLeaveDetail, setPopupViewLeaveDetail] = useState(false);

  //leave
  const [showPopupInputLeave, setShowPopupInputLeave] = useState(false);
  const [inputLeaveData, setInputLeaveData] = useState(null);
  const [showPopupGuarantee, setShowPopupGuarantee] = useState(false);
  const [inputGuaranteeData, setInputGuaranteeData] = useState(null);
  const [showPopupLeaveWithOutPay, setPopupLeaveWithOutPay] = useState(false);
  const [inputLeaveWithOutPay, setInputLeaveWithOutPay] = useState(null);
  const [showPopupAbsentWork, setShowPopupAbsentWork] = useState(false);
  const [inputAbsentWork, setInputAbsentWork] = useState(null);
  const [showPopupBlame, setShowPopupBlame] = useState(false);
  const [inputBlameData, setInputBlameData] = useState(null);

  //master state
  const [attendanceLeaveList, setAttendanceLeaveList] = useState([]);

  useEffect(() => {
    if (refId) {
      downloadImportLeave();
    }
  }, [refId]);

  useEffect(() => {
    if (timeAttendanceData.enterpriseId) {
      downloadAttendanceLeaveList();
    }
  }, [timeAttendanceData]);

  useEffect(() => {
    if (timeAttendanceData?.employeeNumber) {
      reCalManHour();
    }
  }, [
    list,
    timeAttendaceRender[0]?.fingerprint1,
    timeAttendaceRender[0]?.fingerprint2,
    timeAttendaceRender[0]?.fingerprint3,
    timeAttendaceRender[0]?.fingerprint4,
    timeAttendaceRender[0]?.fingerprint5,
    timeAttendaceRender[0]?.fingerprint6,
    timeAttendaceRender[0]?.processManHour,
    timeAttendaceRender[0]?.processOt,
  ]);

  useEffect(() => {
    if (timeAttendanceData) {
      setUpdateAttendanceData({
        missLeaveDoc: timeAttendanceData?.missLeaveDoc || false,
      });
    }
  }, [timeAttendanceData?.missLeaveDoc]);

  const downloadImportLeave = async () => {
    //หาข้อมูลการลา
    const timeData = await getFirestoreDataById("TimeAttendance", refId);
    if (!timeData.status) {
      dialog.alert({
        message: `เกิดข้อผิดพลาด (downloadImportLeave1) : ${timeData.message}`,
      });
      return;
    }
    //หาข้อมูลพนักงาน
    const findEmployeeProfile = await getRealtimeData(
      `Employee/${timeData?.data?.employeeId || ""}`
    );
    if (!findEmployeeProfile.status) {
      dialog.alert({
        message: `เกิดข้อผิดพลาด (downloadImportLeave2) : ${findEmployeeProfile.message}`,
      });

      return;
    }

    const isOverMaxSalaryPosition =
      Number(formatNumber(findEmployeeProfile.data?.salaryPosition) || 0) >=
      5000;

    const employeeInit = {
      employeeProfile: findEmployeeProfile.data,
      firstname: findEmployeeProfile.data.firstname,
      lastname: findEmployeeProfile.data.lastname,
      employeeNumber: findEmployeeProfile.data.employeeNumber,
      startJobDate: findEmployeeProfile.data.startJobDate,
      salaryPosition: findEmployeeProfile.data.salaryPosition,
      // processOt: isOverMaxSalaryPosition ? false : timeData.data.processOt,
      disableOt: isOverMaxSalaryPosition ? true : false,
      positionId: findEmployeeProfile.data.positionId,
    };

    //master
    setTimeAttendanceMaster({
      ...timeData.data,
      ...employeeInit,
    });
    //general
    setTimeAttendanceData({
      ...timeData.data,
      ...employeeInit,
    });
    //set backup too
    setTimeAttendanceBackup({
      ...timeData.data,
      ...employeeInit,
    });
    let render = [];
    render.push({
      ...timeData.data,
      employeeProfile: findEmployeeProfile.data,
    });
    setTimeAttendaceRender(render);

    //หาข้อมูลการลาทั้งหมดของรายการเข้างานวันที่เลือก
    const queries = [["timeAttendanceId", "==", refId]];
    const results = await getFirestoreDataWithMultiQuery("TotalLeave", queries);
    if (!results.status) {
      dialog.alert({
        message: `เกิดข้อผิดพลาด (downloadImportLeave3) : ${results.message}`,
      });
      return;
    }
    const getUserTransactionProfile = async (userId) => {
      return new Promise(async (resolve) => {
        const user = await getRealtimeData(`User/${userId}`);
        if (user.status) {
          resolve(user.data);
        } else {
          resolve(null);
        }
      });
    };

    //load master attendanceLeave
    const attendanceLeaveMaster = await getFirestoreData(
      "AttendanceSettingLeave"
    );
    //หา log ผู้ทำรายการ
    const findProfileTransaction = results.data.map(async (data) => {
      const profile = await getUserTransactionProfile(data.transactionId);
      let titleLabel = data.titleLabel || "";
      if (data.leaveType === "absentWork") {
        titleLabel = "ขาดงาน";
      }
      if (data.subTitleId && attendanceLeaveMaster?.status) {
        //กรณีมี subTitleId ให้ดึงข้อมูลจาก subTitleId
        const findAttendanceMaster = attendanceLeaveMaster?.data?.find((d) => {
          return d.id === data.leaveId;
        });
        if (findAttendanceMaster) {
          const subTitle = findAttendanceMaster.subTitle.find((s) => {
            return s.id === data.subTitleId;
          });
          if (subTitle) {
            titleLabel = subTitle.label;
          }
        }
      }
      return {
        ...data,
        transactionProfile: profile,
        titleLabel: titleLabel,
      };
    });
    const resultsProfile = await Promise.all(findProfileTransaction);

    setList(resultsProfile);
  };

  const handleOptions = async (option, values) => {
    if (option === "view") {
      setPopupViewLeaveDetail(true);
      setViewLeaveDetailData(values);
      return;
    }

    if (option === "delete") {
      if (!values.id && values.key) {
        //ยังไม่บันทึก
        cancleLeave(values);
        return;
      }

      const isConfirm = await dialog.confirm({
        title: `ยกเลิกการบันทึกการลา ใช่ หรือ ไม่ ?`,
        message: `หากลบแล้วจำนวนชั่วโมงที่ใช้จะถูกคืนค่ากลับไปที่สิทธิที่พนักงานสามารถใช้ได้ (ถ้ามี)`,
      });
      if (!isConfirm) {
        return;
      }
      cancleLeave(values);
      return;
    }
  };

  const cancleLeave = async (values) => {
    if (!values.id) {
      if (values.key) {
        //ยังไม่บันทึก
        const findIndexOfList = list.findIndex((d) => {
          return d.key === values.key;
        });
        let updateList = [...list];
        if (findIndexOfList !== -1) {
          updateList.splice(findIndexOfList, 1);
          setList(updateList);
        }
      }
      return;
    }
    if (values.id) {
      const findIndexOfList = list.findIndex((d) => {
        return d.id === values.id;
      });
      let updateList = [...list];
      if (findIndexOfList !== -1) {
        updateList[findIndexOfList] = {
          ...updateList[findIndexOfList],
          status: "N",
          isSync: true,
        };
        setList(updateList);
      }
    }
  };

  const downloadAttendanceLeaveList = async () => {
    //รายการลาทั้งหมด
    const queries = [
      ["active", "==", "Y"],
      ["enterpriseList", "array-contains", timeAttendanceData.enterpriseId],
    ];
    const attendanceList = await getFirestoreDataWithMultiQuery(
      "AttendanceSettingLeave",
      queries
    );
    if (!attendanceList.status) {
      await dialog.alert({ message: attendanceList.message });
      return;
    }

    const filterInBranch = attendanceList.data.filter((d) => {
      return d.branchList.includes(timeAttendanceData.branchId);
    });

    const getAttendanceLabel = await getFirestoreData("AttendanceSettingLabel");
    if (!getAttendanceLabel.status) {
      await dialog.alert({ message: getAttendanceLabel.message });
      return;
    }

    let init = [];
    filterInBranch.forEach((d) => {
      let findLabel = getAttendanceLabel.data.find((l) => {
        return l.id === d.title;
      });
      init.push({ ...d, titleLabel: findLabel?.label });
    });

    setAttendanceLeaveList(init);
  };

  const renderDate = () => {
    if (!timeAttendanceData) {
      return "-";
    }
    if (!timeAttendanceData.date) {
      return "-";
    }

    return moment(timeAttendanceData.date.toDate()).format("DD/MM/YYYY");
  };

  const renderEmployee = () => {
    if (!timeAttendanceData) {
      return "-";
    }
    if (!timeAttendanceData.employeeProfile) {
      return "-";
    }

    return `${timeAttendanceData.employeeProfile.firstname} ${timeAttendanceData.employeeProfile.lastname} (${timeAttendanceData.employeeProfile.employeeNumber})`;
  };

  const handleLeavePopup = (option) => {
    const employeeProfile = {
      firstname: timeAttendanceData.firstname,
      lastname: timeAttendanceData.lastname,
      employeeNumber: timeAttendanceData.employeeNumber,
      startJobDate: timeAttendanceData.startJobDate,
      enterpriseId: timeAttendanceData.enterpriseId,
      branchId: timeAttendanceData.branchId,
    };
    const filterImportLeave = list.filter((l) => {
      return l.status === "Y" && !l.id;
    });

    let params = {
      id: timeAttendanceData.employeeId, //<= ส่งเพื่อดึงค่ารายการลาของคนนี้
      timeAttendanceId: timeAttendanceData.id, //<= ส่งเป็น ref ไม่ได้ใช้
      ...employeeProfile, //<= ส่งแสดงชื่อ
      importLeave: filterImportLeave, //<= ส่งเพื่อ prepare การลา
      date: moment(timeAttendanceData.date.toDate()), //<= ส่งเพื่อแสดงวันที่
    };

    if (option === "leave") {
      setShowPopupInputLeave(true);
      setInputLeaveData(params);
      return;
    }
    if (option === "leaveWithOutPay") {
      setPopupLeaveWithOutPay(true);
      setInputLeaveWithOutPay(params);
      return;
    }
    if (option === "guarantee") {
      setShowPopupGuarantee(true);
      setInputGuaranteeData(params);
      return;
    }
    if (option === "blame") {
      setShowPopupBlame(true);
      setInputBlameData(params);
      return;
    }
    if (option === "missLeaveDoc") {
      handleMissLeaveDoc();
      return;
    }
    if (option === "absentWork") {
      // handleAbsentWork();
      setShowPopupAbsentWork(true);
      setInputAbsentWork(params);
      return;
    }
  };

  const handleLeave = async (values) => {
    const getInputData = (leaveType = "") => {
      switch (leaveType) {
        case "leave":
          return inputLeaveData;
        case "guarantee":
          return inputGuaranteeData;
        case "leaveWithOutPay":
          return inputLeaveWithOutPay;
        case "blame":
          return inputBlameData;
        case "absentWork":
          return inputAbsentWork;
        default:
          return null;
      }
    };
    const inputData = getInputData(values.leaveType);
    if (!inputData) {
      await dialog.alert({ message: "ไม่พบข้อมูลการบันทึก" });
      return;
    }
    //แปลงค่า moment เป็น date ที่เก็บได้ใน firebase
    const date = moment.utc(values.date).startOf("day");
    values.date = Timestamp.fromDate(date.toDate());
    values.startDate = Timestamp.fromDate(
      moment.utc(values.startDate).startOf("day").toDate()
    );
    values.endDate = Timestamp.fromDate(
      moment.utc(values.endDate).endOf("day").toDate()
    );
    if (values.giveDate) {
      values.giveDate = momentToUTCDateTimestamp(values.giveDate);
    }
    if (values.approveDate) {
      values.approveDate = momentToUTCDateTimestamp(values.approveDate);
    }
    values.day = Number(date.format("D"));
    values.month = Number(date.format("M"));
    values.year = Number(date.format("YYYY"));
    if (values.leaveType === "absentWork") {
      values.titleLabel = "ขาดงาน";
    }

    // ตรวจสอบการว่ามีรายการลาอื่นๆก่อนหน้าหรือไม่
    let initData = { ...timeAttendanceData, importLeave: [...list] };
    const convertData = {
      ...convertImportLeavePattern(values),
      key: `${values.leaveDayType}_${uuid()}`,
      isSync: true,
    };
    // ตรวจสอบว่า values เป็น iterable หรือไม่
    let importLeave = [];
    if (initData.importLeave) {
      if (Array.isArray(values)) {
        importLeave = [...initData.importLeave, ...convertData];
      } else {
        importLeave = [...initData.importLeave, convertData]; // กรณี values ไม่ใช่ array
      }
    } else {
      if (Array.isArray(values)) {
        importLeave = [...convertData];
      } else {
        importLeave = [convertData]; // กรณี values ไม่ใช่ array
      }
    }

    //validates

    const filterAvailableOnly = importLeave.filter((l) => {
      return l.status === "Y";
    });
    //ตรวจสอบการขาดงานแบบเต็มวัน
    const findAbsentWork = importLeave.filter((l) => {
      return (
        l.leaveType === "absentWork" &&
        l.leaveDayType === "full" &&
        l.status === "Y"
      );
    });
    if (findAbsentWork.length > 0 && filterAvailableOnly.length > 1) {
      dialog.alert({
        message: "รายการนี้มีการขาดงานแบบเต็มวัน ไม่สามารถบันทึกการลาได้อีก",
      });
      return;
    }
    //ตรวจสอบก่อนว่ามีการรับรองเวลา หรือลา แบบเต็มวันหรือไม่
    // const filterFullLeave = importLeave.filter((l) => {
    //   return (
    //     l.leaveDayType === "full" &&
    //     ((["guarantee", "leaveWithOutPay"].includes(l.leaveType) &&
    //       l.processManHour) ||
    //       l.leaveType === "leave") &&
    //     l.status === "Y"
    //   );
    // });
    const filterFullLeave = importLeave.filter((l) => {
      return (
        l.leaveDayType === "full" &&
        ["leave", "guarantee", "leaveWithOutPay", "blame"].includes(
          l.leaveType
        ) &&
        l.processManHour &&
        l.status === "Y"
      );
    });

    if (filterFullLeave.length > 0 && filterAvailableOnly.length > 1) {
      await dialog.alert({
        message: "ไม่สามารถรับรองการลาเต็มวันได้มากกว่า 1 รายการ",
      });
      return;
    }
    initData.importLeave = importLeave;

    setIsFirstOpenView(false);
    //set data
    setTimeAttendanceData(initData);
    //for display list table
    setList(importLeave);

    // disable popup
    setShowPopupInputLeave(false);
    setInputLeaveData(null);
    setShowPopupGuarantee(false);
    setInputGuaranteeData(null);
    setPopupLeaveWithOutPay(false);
    setInputLeaveWithOutPay(null);
    setShowPopupAbsentWork(false);
    setInputAbsentWork(null);
    setShowPopupBlame(false);
    setInputBlameData(null);
  };

  const reCalManHour = async () => {
    let initData = { ...timeAttendanceData, importLeave: [...list] };
    // calManhour
    const calManHour = calculateManHour(initData, attendanceInit);
    initData = {
      ...initData,
      ...(!isFirstOpenView ? calManHour : {}),
      manHourString: initData.processManHour
        ? calManHour.manHourString
        : "00:00",
      otString: initData.processOt ? calManHour.otString : "00:00",
    };

    //for display manhour
    let renders = [];
    renders.push(initData);
    console.log("RENDERS", renders);
    setTimeAttendaceRender(renders);
  };

  const renderOtherStatus = () => {
    if (!timeAttendanceData) {
      return "-";
    }
    let message = "";
    if (timeAttendanceData.missLeaveDoc) {
      message += "ยังไม่ส่งใบลา";
    }
    if (timeAttendanceData.absentWork) {
      message += "ขาดงาน";
    }
    return message ? message : "-";
  };

  const handleSave = async () => {
    const isConfirm = await dialog.confirm({
      title: `บันทึกการลาทั้งหมด ใช่ หรือ ไม่ ?`,
      message: `ยืนยันการบันทึกการลาทั้งหมด`,
    });
    if (!isConfirm) {
      return;
    }

    const filterOnlySyncAdd = list.filter((l) => {
      return l.isSync && !l.id;
    });
    const filterOnlySyncUpdate = list.filter((l) => {
      return l.isSync && l.id;
    });

    //4. get user transaction
    if (!user.uid) {
      await dialog.alert({ message: "ไม่พบข้อมูลผู้ใช้งาน" });
      return;
    }
    const userProfile = await getRealtimeData(`User/${user.uid}`);
    if (!userProfile.status) {
      await dialog.alert({ message: userProfile.message });
      return;
    }
    const profile = userProfile.data;
    const userTransaction = {
      transactionId: user?.uid || "",
      transactionName: `${profile?.firstname} ${profile?.lastname}`,
      transactionEmail: profile?.email,
      transactionRole: profile?.role,
      transactionDate: currentTimestampFirestore(),
      createDate: Date.now(),
    };

    const timeData = timeAttendaceRender[0];

    const addOnUpdateValues = {
      //time data
      fingerprint1: timeData.fingerprint1 || "",
      fingerprint1Log: [
        ...timeData.fingerprint1Log,
        {
          fingerprint: timeData.fingerprint1 || "",
          ...userTransaction,
        },
      ],
      fingerprint2: timeData.fingerprint2 || "",
      fingerprint2Log: [
        ...timeData.fingerprint2Log,
        {
          fingerprint: timeData.fingerprint2 || "",
          ...userTransaction,
        },
      ],
      fingerprint3: timeData.fingerprint3 || "",
      fingerprint3Log: [
        ...timeData.fingerprint3Log,
        {
          fingerprint: timeData.fingerprint3 || "",
          ...userTransaction,
        },
      ],
      fingerprint4: timeData.fingerprint4 || "",
      fingerprint4Log: [
        ...timeData.fingerprint4Log,
        {
          fingerprint: timeData.fingerprint4 || "",
          ...userTransaction,
        },
      ],
      fingerprint5: timeData.fingerprint5 || "",
      fingerprint5Log: [
        ...timeData.fingerprint5Log,
        {
          fingerprint: timeData.fingerprint5 || "",
          ...userTransaction,
        },
      ],
      fingerprint6: timeData.fingerprint6 || "",
      fingerprint6Log: [
        ...timeData.fingerprint6Log,
        {
          fingerprint: timeData.fingerprint6 || "",
          ...userTransaction,
        },
      ],
      //manhour
      processManHour: timeData.processManHour,
      manHour: timeData.manHour || 0,
      manHourMinute: timeData.manHourMinute || 0,
      manHourTotalMinute: timeData.manHourTotalMinute || 0,
      manHourTotalMinuteLog: [
        ...timeData.manHourTotalMinuteLog,
        {
          manHourTotalMinute: timeData.manHourTotalMinute || 0,
          ...userTransaction,
        },
      ],
      manHourString: timeData.manHourString || "00:00",
      manHourStringLog: [
        ...timeData.manHourStringLog,
        {
          manHourString: timeData.manHourString || "00:00",
          ...userTransaction,
        },
      ],
      manHourWithOutPayMinute: timeData.manHourWithOutPayMinute || 0,
      manHourProcessMinute: timeData.manHourProcessMinute || 0,
      manHourProcessMinuteLog: [
        ...timeData.manHourProcessMinuteLog,
        {
          manHourProcessMinute: timeData.manHourProcessMinute || 0,
          ...userTransaction,
        },
      ],
      totalWorkTime: timeData.totalWorkTime || 0,
      totalWorkTimeMinute: timeData.totalWorkTimeMinute || 0,
      totalWorkTimeString: timeData.totalWorkTimeString || "00:00",
      totalWorkTimeStringLog: [
        ...timeData.totalWorkTimeStringLog,
        {
          totalWorkTimeString: timeData.totalWorkTimeString || "00:00",
          ...userTransaction,
        },
      ],
      isLateMorning: timeData.isLateMorning || false,
      lateMorningMinute: timeData.lateMorningMinute || 0,
      isLateAfternoon: timeData.isLateAfternoon || false,
      lateAfternoonMinute: timeData.lateAfternoonMinute || 0,
      breakLateMinute: timeData.breakLateMinute || 0,
      isBreakLate: timeData.isBreakLate || false,
      // //leave
      // importLeave: timeAttendaceRender.importLeave || [], //รายการลา
      importLeaveLog: [...(timeData.importLeaveLog || [])], //ประวัติรายการลา
      leaveHour: timeData.leaveHour || 0,
      leaveHourMinute: timeData.leaveHourMinute || 0,
      leaveHourTotalMinute: timeData.leaveHourTotalMinute || 0,
      leaveHourString: timeData.leaveHourString || "00:00",
      //ot
      processOt: timeData.processOt || false,
      otMinute: timeData.otMinute || 0,
      otHour: timeData.otHour || 0,
      otString: timeData.otString || "00:00",
      otTotalMinute: timeData.otTotalMinute || 0,
      otTotalMinuteLog: [
        ...(timeData.otTotalMinuteLog || []),
        {
          otTotalMinute: timeData.otTotalMinute || 0,
          ...userTransaction,
        },
      ],
      otTotalString: timeData.otTotalString || "00:00",
      // disableOt: data.disableOt || false,
      //create update by
      logIndex: Number(timeData.logIndex) + 1 || 0,
      createBy: [...timeData.createBy, { ...userTransaction }],
      updateBy: [...timeData.updateBy, { ...userTransaction }],
      //add on before delete not use
      profile: profile,
      userTransaction: userTransaction,
      updateDate: currentTimestampFirestore(),
    };

    //ตรวจสอบว่าข้อมูลมีการเปลี่ยนแปลงหรือไม่
    let isChange = false;

    if (
      filterOnlySyncAdd.length > 0 ||
      filterOnlySyncUpdate.length > 0 ||
      timeAttendanceMaster.missLeaveDoc !== timeAttendanceData.missLeaveDoc
    ) {
      isChange = true;
    }
    //ตรวจสอบการเปลี่ยนแปลง absentWork
    const checkAbSentWork = (importLeave) => {
      return importLeave.some((item) => {
        return item.leaveType === "absentWork";
      });
    };
    //ข้อมูลเดิมที่ไม่เปลี่ยนแปลง
    const filterOnlyNotSync = list.filter((l) => {
      return !l.isSync && l.id;
    });
    //list รายการลาทั้งหมด
    let mergeImportLeave = [
      ...filterOnlyNotSync,
      ...filterOnlySyncAdd,
      ...filterOnlySyncUpdate,
    ].filter((d) => {
      return d.status === "Y";
    });
    const isAbsentWork = checkAbSentWork(mergeImportLeave);
    if (isAbsentWork !== timeAttendanceMaster.absentWork) {
      isChange = true;
    }

    //ตรวจสอบการวางนิ้ว
    const tm = { ...timeAttendanceMaster };
    const tr = { ...timeAttendaceRender[0] };

    if (!validateFingerprintsLevel(tr)) {
      dialog.alert({
        message: "กรุณาตรวจสอบข้อมูลการวางนิ้วให้ถูกต้อง (HH:mm)",
      });
      return;
    }
    tm.processManHour = tm.processManHour ? "1" : "0";
    tm.processOt = tm.processOt ? "1" : "0";
    tr.processManHour = tr.processManHour ? "1" : "0";
    tr.processOt = tr.processOt ? "1" : "0";

    if (
      tm.fingerprint1 !== tr.fingerprint1 ||
      tm.fingerprint2 !== tr.fingerprint2 ||
      tm.fingerprint3 !== tr.fingerprint3 ||
      tm.fingerprint4 !== tr.fingerprint4 ||
      tm.fingerprint5 !== tr.fingerprint5 ||
      tm.fingerprint6 !== tr.fingerprint6 ||
      tm.processManHour !== tr.processManHour ||
      tm.processOt !== tr.processOt
    ) {
      isChange = true;
    }

    if (!isChange) {
      await dialog.alert({ message: "ไม่พบการเปลี่ยนแปลงข้อมูล" });
      return;
    }
    const employeeRef = {
      enterpriseId: timeAttendanceMaster.enterpriseId,
      branchId: timeAttendanceMaster.branchId,
      employeeKey: timeAttendanceMaster.employeeId,
      employeeNumber: timeAttendanceMaster.employeeNumber,
      employeeName: timeAttendanceMaster.employeeName,
    };

    const responseToSave = {
      timeAttendanceId: refId,
      importLeaveAdd: filterOnlySyncAdd,
      importLeaveUpdate: filterOnlySyncUpdate,
      updateValues: {
        ...addOnUpdateValues,
        ...updateAttendanceData,
        absentWork: isAbsentWork,
      },
      isChange: isChange,
      employeeRef: employeeRef,
      userTransaction: userTransaction,
    };

    if (onEnter) {
      onEnter(responseToSave);
    }
  };

  const handleMissLeaveDoc = async () => {
    const isMissLeaveDoc = timeAttendanceData?.missLeaveDoc ? true : false;
    const title = isMissLeaveDoc
      ? `คุณต้องการยกเลิก "การระบุว่ารายการนี้ยังไม่ส่งใบลา" ใช่หรือไม่?`
      : `คุณต้องการระบุรายการนี้ยังไม่ส่งใบลา ใช่ หรือ ไม่ ?`;
    const isConfirm = await dialog.confirm({
      title: title,
      message: `ยืนยันรายการ`,
    });
    if (!isConfirm) {
      return;
    }
    const missLeaveDoc = isMissLeaveDoc ? false : true;
    setTimeAttendanceData({
      ...timeAttendanceData,
      missLeaveDoc: missLeaveDoc,
    });
  };

  // const handleAbsentWork = async () => {
  //   const isAbsentWork = timeAttendanceData?.absentWork ? true : false;
  //   const isMissLeaveDoc = timeAttendanceData?.missLeaveDoc ? true : false;
  //   const title = isAbsentWork
  //     ? `คุณต้องการยกเลิก "การระบุว่ารายการนี้เป็นการขาดงาน" ใช่หรือไม่?`
  //     : `คุณต้องการระบุรายการนี้เป็นการขาดงาน ใช่ หรือ ไม่ ?`;
  //   const message = isAbsentWork
  //     ? `ยืนยันรายการ`
  //     : `หากคุณระบุเป็นการขาดงาน ระบบจะไม่คำนวณชั่วโมงการทำงาน และรายการลาทั้งหมดจะถูกลบออก`;
  //   const isConfirm = await dialog.confirm({
  //     title: title,
  //     message: message,
  //   });
  //   if (!isConfirm) {
  //     return;
  //   }
  //   const absentWork = isAbsentWork ? false : true;
  //   const missLeaveDoc = absentWork ? false : isMissLeaveDoc;
  //   console.log("ยกเลิกการขาดงาน", absentWork);

  //   setTimeAttendanceData({
  //     ...timeAttendanceData,
  //     absentWork: absentWork,
  //   });
  //   //remove all leave and other status
  //   if (absentWork) {
  //     //ขาดงาน
  //     setListBackup([...list]);
  //     setTimeAttendanceBackup({ ...timeAttendanceData });
  //     //กรณีระบว่าขาดงาน ข้อมูลบันทึกการลาที่เคยลาให้ status === "N" ด้วย
  //     let initListChangeToInActive = [];
  //     list.forEach((l) => {
  //       if ((l.id && l.status === "Y") || (l.id && l.isSync)) {
  //         initListChangeToInActive.push({
  //           ...l,
  //           status: "N",
  //           isSync: true,
  //         });
  //       }
  //     });
  //     setTimeAttendanceData({
  //       ...timeAttendanceData,
  //       importLeave: [...initListChangeToInActive],
  //       missLeaveDoc: false,
  //       absentWork: true,
  //       fingerprint1: "",
  //       fingerprint2: "",
  //       fingerprint3: "",
  //       fingerprint4: "",
  //       fingerprint5: "",
  //       fingerprint6: "",
  //     });
  //     setList([...initListChangeToInActive]);
  //   } else {
  //     setTimeAttendanceData({ ...timeAttendanceBackup, absentWork: false });
  //     setList([...(listBackup || [])]);
  //     setListBackup([]);
  //     setTimeAttendanceBackup(null);
  //   }
  // };

  return (
    <PopupBackdrop>
      {popupViewLeaveDetail ? (
        <PopupViewLeaveDetail
          refData={viewLeaveDetailData}
          onClose={() => {
            setPopupViewLeaveDetail(false);
          }}
        />
      ) : null}

      {showPopupInputLeave ? (
        <PopupImportLeave
          attendanceLeaveList={attendanceLeaveList}
          data={inputLeaveData}
          dateFix={inputLeaveData?.date}
          onEnter={(values) => {
            handleLeave({
              ...values,
              leaveType: "leave",
            });
          }}
          onClose={() => {
            setShowPopupInputLeave(false);
            setInputLeaveData(null);
          }}
        />
      ) : null}
      {showPopupGuarantee ? (
        <PopupImportGuarantee
          attendanceLeaveList={attendanceLeaveList}
          data={inputGuaranteeData}
          dateFix={inputGuaranteeData?.date}
          onEnter={(values) => {
            handleLeave({
              ...values,
              leaveType: "guarantee",
            });
          }}
          onClose={() => {
            setShowPopupGuarantee(false);
            setInputGuaranteeData(null);
          }}
        />
      ) : null}

      {showPopupBlame ? (
        <PopupImportBlame
          attendanceLeaveList={attendanceLeaveList}
          data={inputBlameData}
          dateFix={inputBlameData?.date}
          onEnter={(values) => {
            handleLeave({
              ...values,
              leaveType: "blame",
            });
          }}
          onClose={() => {
            setShowPopupBlame(false);
            setInputBlameData(null);
          }}
        />
      ) : null}

      {showPopupLeaveWithOutPay ? (
        <PopupImportLeaveWithOutPay
          attendanceLeaveList={attendanceLeaveList}
          data={inputLeaveWithOutPay}
          dateFix={inputLeaveWithOutPay?.date}
          onEnter={(values) => {
            handleLeave({
              ...values,
              leaveType: "leaveWithOutPay",
            });
          }}
          onClose={() => {
            setPopupLeaveWithOutPay(false);
            setInputLeaveWithOutPay(null);
          }}
        />
      ) : null}

      {showPopupAbsentWork ? (
        <PopupAbsentWork
          attendanceLeaveList={attendanceLeaveList}
          data={inputAbsentWork}
          dateFix={inputAbsentWork?.date}
          onEnter={(values) => {
            handleLeave({
              ...values,
              leaveType: "absentWork",
            });
          }}
          onClose={() => {
            setShowPopupAbsentWork(false);
            setInputAbsentWork(null);
          }}
        />
      ) : null}

      <View style={Styles.container}>
        <Header
          id={refId}
          onEnter={() => {
            handleSave();
          }}
          onClose={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
        <ScrollView>
          <Text style={Styles.subTitle}>ข้อมูล</Text>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Text>วันที่ : {renderDate()}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>ข้อมูลพนักงาน : {renderEmployee()}</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text>สถานะอื่นๆ : {renderOtherStatus()}</Text>
            </Grid>
            <Grid item xs={12}>
              <TableViewLeaveRecal
                tableData={timeAttendaceRender}
                showPageSelect={false}
                pageControl={{ currentPage: 1, limitItemsPerPage: 1 }}
                onChange={(values) => {
                  const updateValues = {
                    fingerprint1: values.fingerprint1,
                    fingerprint2: values.fingerprint2,
                    fingerprint3: values.fingerprint3,
                    fingerprint4: values.fingerprint4,
                    fingerprint5: values.fingerprint5,
                    fingerprint6: values.fingerprint6,
                    processManHour: values.processManHour,
                    processOt: values.processOt,
                  };
                  setTimeAttendanceData({
                    ...timeAttendanceData,
                    ...updateValues,
                  });
                  const timeRender = [...timeAttendaceRender];
                  timeRender[0] = { ...timeRender[0], ...updateValues };
                  setTimeAttendaceRender(timeRender);
                  setIsFirstOpenView(false);
                }}
              />
            </Grid>
          </Grid>
          <Line />
          <Text style={Styles.subTitle}>บันทึกการลาเพิ่ม</Text>
          <View style={Styles.menuContainer}>
            <OptionButtons
              title={`บันทึก\nการลา\nตามสิทธิ์`}
              onPress={() => {
                handleLeavePopup("leave");
              }}
              disable={attendanceLeaveList.length === 0}
            >
              <FaCalendarAlt
                style={{
                  fontSize: 20,
                  color: "#4652D1",
                }}
              />
            </OptionButtons>
            <View style={Styles.spaceMenu} />
            <OptionButtons
              title={`บันทึก\nการลา`}
              onPress={() => {
                handleLeavePopup("leaveWithOutPay");
              }}
              disable={attendanceLeaveList.length === 0}
            >
              <FaCalendarPlus
                style={{
                  fontSize: 20,
                  color: "#4652D1",
                }}
              />
            </OptionButtons>
            <View style={Styles.spaceMenu} />
            <OptionButtons
              title={`รับรอง\nเวลา`}
              onPress={() => {
                handleLeavePopup("guarantee");
              }}
              disable={attendanceLeaveList.length === 0}
            >
              <FaCalendarCheck
                style={{
                  fontSize: 20,
                  color: "#4652D1",
                }}
              />
            </OptionButtons>
            <View style={Styles.spaceMenu} />
            <OptionButtons
              title={
                timeAttendanceData?.missLeaveDoc
                  ? `ยกเลิก\nส่งใบลาแล้ว`
                  : `ยังไม่ส่ง\nใบลา`
              }
              onPress={() => {
                handleLeavePopup("missLeaveDoc");
              }}
            >
              <HiDocumentRemove
                style={{
                  fontSize: 20,
                  color: timeAttendanceData?.missLeaveDoc
                    ? "#FF0000"
                    : "#4652D1",
                }}
              />
            </OptionButtons>
            <View style={Styles.spaceMenu} />
            <OptionButtons
              title={`ขาดงาน`}
              onPress={() => {
                handleLeavePopup("absentWork");
              }}
            >
              <FaUserTimes
                style={{
                  fontSize: 20,
                  color: "#4652D1",
                }}
              />
            </OptionButtons>
            <View style={Styles.spaceMenu} />
            <OptionButtons
              title={`บันทึก\nโทษ`}
              onPress={() => {
                handleLeavePopup("blame");
              }}
            >
              <MdBlock
                style={{
                  fontSize: 20,
                  color: "#4652D1",
                }}
              />
            </OptionButtons>
          </View>
          <Line />

          <Text style={Styles.subTitle}>รายการลาทั้งหมด</Text>
          <TablePopupImportLeaveView
            tableData={list}
            onOption={(values) => {
              handleOptions(values.option, values);
            }}
          />
        </ScrollView>
      </View>
    </PopupBackdrop>
  );
};

const Header = (props) => {
  const { id } = props;
  const { onClose, onEnter } = props;
  return (
    <View style={{ width: "100%" }}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 1 }}>
          {" "}
          <Text style={Styles.title}>
            จัดการข้อมูล การวางนิ้ว และบันทึกรายการลา
          </Text>
          <Text style={{ fontSize: 7, color: "#9c9c9c" }}>{`รหัสตรวจสอบ : ${
            id || ""
          }`}</Text>
        </View>

        <Button
          style={{ width: 100 }}
          buttonText={"บันทึก"}
          color={"primary"}
          onClick={() => {
            if (onEnter) {
              onEnter();
            }
          }}
        />
        <View style={{ width: 20 }} />
        <Button
          style={{ width: 100 }}
          buttonText={"ปิด"}
          color={"secondary"}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
      </View>
      <Divider />
    </View>
  );
};

export default PopupImportLeaveView;

const Line = (props) => {
  return (
    <View
      style={{
        width: "100%",
        height: 1,
        backgroundColor: "#ebebeb",
        marginTop: 20,
        marginBottom: 20,
      }}
    />
  );
};

const OptionButtons = (props) => {
  const { title, disable = false } = props;
  const { onPress } = props;
  return (
    <TouchableOpacity
      style={{ justifyContent: "center", alignItems: "center" }}
      activeOpacity={disable ? 1 : 0.5}
      onPress={() => {
        if (disable) {
          return;
        }
        onPress && onPress();
      }}
    >
      {props.children}
      <Text
        style={{
          fontSize: 12,
          lineHeight: 12,
          color: "#000000",
          marginTop: 5,
          textAlign: "center",
        }}
      >
        {title || ""}
      </Text>
    </TouchableOpacity>
  );
};

const Styles = {
  container: {
    width: "90vw",
    height: "90vh",
    maxWidth: 1200,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
    zIndex: 10,
  },
  content: {
    flex: 1,
    zIndex: 0,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    flex: 1,
  },
  subTitle: {
    fontSize: 17,
    fontWeight: "bold",
    marginBottom: 10,
  },
  menuContainer: {
    marginTop: 10,
    flexDirection: "row",
    alignItems: "flex-start",
  },
  spaceMenu: {
    width: 15,
  },
};
