import uuid from "react-uuid";
import {
  addFirestoreData,
  currentTimestampFirestore,
  getFirestoreData,
  getFirestoreDataWithMultiQuery,
  uploadFileToStorage,
} from "../../../../service/firebase";
import moment from "moment";
import { convertBlobUrlToFile } from "../../../../function/format";

export const validateFile = async (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    let responseJson = [];
    reader.onload = async () => {
      let lines = await reader.result.split("\n");
      let dataHeader;
      lines.forEach((item, index) => {
        let data = item.split(",");
        if (index === 0) {
          dataHeader = item.split(",").filter((value) => value.trim() !== "");
          //validate header
          if (dataHeader.length < 7) {
            resolve({
              status: false,
              message:
                "รูปแบบไฟล์ไม่ถูกต้อง ควรจะต้องมี column อย่างน้อย (แผนก,รหัสพนักงาน,ชื่อ,วันที่,การวางนิ้ว1,Total Work Time)",
            });
            return;
          }
          if (
            dataHeader[0] !== "Dept. Name" ||
            dataHeader[1] !== "รหัสพนักงาน" ||
            dataHeader[2] !== "ชื่อ" ||
            dataHeader[3] !== "วันที่" ||
            dataHeader[4] !== "การวางนิ้ว1"
          ) {
            resolve({
              status: false,
              message:
                "รูปแบบไฟล์ไม่ถูกต้อง ควรจะเรียงลำดับ column และตั้งชื่อ column ตามนี้ (Dept. Name,รหัสพนักงาน,ชื่อ,วันที่,การวางนิ้ว1,Total Work Time)",
            });
            return;
          }

          if (
            !dataHeader.includes("Total Work Time") ||
            !dataHeader.includes("การวางนิ้ว1")
          ) {
            resolve({
              status: false,
              message:
                "รูปแบบไฟล์ไม่ถูกต้อง ควรจะต้องมี column อย่างน้อย (การวางนิ้ว1,Total Work Time)",
            });
            return;
          }
        }

        if (item) {
          let obj = {
            fingerprint1: "",
            fingerprint2: "",
            fingerprint3: "",
            fingerprint4: "",
            fingerprint5: "",
            fingerprint6: "",
          };
          obj["processManHour"] = true;
          obj["processOt"] = true;
          obj["key"] = uuid();
          obj["section"] = data[0];
          obj["employeeNumber"] = data[1];
          obj["employeeName"] = data[2];
          obj["date"] = data[3].replace(/[^0-9/-]/g, "");
          obj["fingerprint1"] = data[4].replace(/[^0-9:]/g, "");
          if (dataHeader.length === 7) {
            obj["totalWorkTime"] = data[6];
          } else if (dataHeader.length === 8) {
            obj["fingerprint2"] = data[5].replace(/[^0-9:]/g, "");
            obj["totalWorkTime"] = data[7];
          } else if (dataHeader.length === 9) {
            obj["fingerprint2"] = data[5].replace(/[^0-9:]/g, "");
            obj["fingerprint3"] = data[6].replace(/[^0-9:]/g, "");
            obj["totalWorkTime"] = data[8];
          } else if (dataHeader.length === 10) {
            obj["fingerprint2"] = data[5].replace(/[^0-9:]/g, "");
            obj["fingerprint3"] = data[6].replace(/[^0-9:]/g, "");
            obj["fingerprint4"] = data[7].replace(/[^0-9:]/g, "");
            obj["totalWorkTime"] = data[9];
          } else if (dataHeader.length === 11) {
            obj["fingerprint2"] = data[5].replace(/[^0-9:]/g, "");
            obj["fingerprint3"] = data[6].replace(/[^0-9:]/g, "");
            obj["fingerprint4"] = data[7].replace(/[^0-9:]/g, "");
            obj["fingerprint5"] = data[8].replace(/[^0-9:]/g, "");
            obj["totalWorkTime"] = data[10];
          } else if (dataHeader.length === 12) {
            obj["fingerprint2"] = data[5].replace(/[^0-9:]/g, "");
            obj["fingerprint3"] = data[6].replace(/[^0-9:]/g, "");
            obj["fingerprint4"] = data[7].replace(/[^0-9:]/g, "");
            obj["fingerprint5"] = data[8].replace(/[^0-9:]/g, "");
            obj["fingerprint6"] = data[9].replace(/[^0-9:]/g, "");
            obj["totalWorkTime"] = data[11];
          }
          if (index > 0) {
            responseJson.push(obj);
          }
        }
      });

      resolve({ status: true, message: "success", data: responseJson });
    };
    reader.readAsText(file);
  });
};

export const convertImportLeavePattern = (data) => {
  let params = {
    //subTitle
    isSubTitle: data.isSubTitle || false,
    parentId: data.parentId || "",
    subTitleId: data.subTitleId || "",
    //employee profile
    employeeKey: data.employeeKey || "",
    employeeNumber: data.employeeNumber || "",
    enterpriseId: data.enterpriseId || "",
    branchId: data.branchId || "",
    //leave data
    titleLabel: data.titleLabel || "",
    leaveId: data.leaveId || "",
    leaveType: data.leaveType,
    leaveDayType: data.leaveDayType || "",
    processManHour: data.processManHour || false,
    //leave date
    date: data.date || moment(),
    startDate: data.startDate || moment(),
    endDate: data.endDate || moment(),
    year: data.year || 0,
    month: data.month || 0,
    day: data.day || 0,
    //leave time
    startTime: data.startTime || "",
    endTime: data.endTime || "",
    totalMinute: data.totalMinute || 0,
    //approve date
    giveDate: data.giveDate || null,
    approveDate: data.approveDate || null,
    //ref
    reasonLeave: data.reasonLeave || "",
    attactment: data.attactment || "",
    bossId: data.bossId || "",
    hrId: data.hrId || "",
    //timestamp
    transactionDate: currentTimestampFirestore(),
    createDate: data.createDate ? data.createDate : currentTimestampFirestore(),
    updateDate: data.updateDate ? data.updateDate : currentTimestampFirestore(),
    //sttaus
    status: data.status || "N",
    file: data.file || null,
  };
  return params;
};

export function convertMinutesToText(minutes) {
  const minutesInHour = 60;
  const hoursInDay = 8;
  const minutesInDay = minutesInHour * hoursInDay;

  const days = Math.floor(minutes / minutesInDay);
  const remainingMinutes = minutes % minutesInDay;

  const hours = Math.floor(remainingMinutes / minutesInHour);
  const mins = remainingMinutes % minutesInHour;

  return `${days} วัน | ${String(hours).padStart(2, "0")}:${String(
    mins
  ).padStart(2, "0")} ชม.`;
}

export function convertMinuteToTimeString(minutes) {
  const minutesInHour = 60;
  const hoursInDay = 8;
  const minutesInDay = minutesInHour * hoursInDay;

  // const days = Math.floor(minutes / minutesInDay);
  const remainingMinutes = minutes % minutesInDay;

  const hours = Math.floor(remainingMinutes / minutesInHour);
  const mins = remainingMinutes % minutesInHour;

  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
}

export const addLeaveData = async (data) => {
  if (!data.timeAttendanceId) {
    return { status: false, message: "refId is required" };
  }
  if (data.attactment) {
    const file = await convertBlobUrlToFile(data.attactment);
    const uploadResults = await uploadFileToStorage(
      file,
      `TimeAttendance/ImportLeave`
    );
    data = {
      ...data,
      attactment: uploadResults,
      createDateTimestamp: currentTimestampFirestore(),
    };
    delete data.file;
  }
  return await addFirestoreData(`TotalLeave`, {
    timeAttendanceId: data.refId,
    ...data,
  });
};

export const getAllAttendanceList = async (enterpriseId, branchId) => {
  let response = { status: false, message: "", data: [] };
  let list = [];
  //รายการลาทั้งหมด
  let queries = [["active", "==", "Y"]];

  if (enterpriseId) {
    queries.push(["enterpriseList", "array-contains", enterpriseId]);
  }
  const attendanceList = await getFirestoreDataWithMultiQuery(
    "AttendanceSettingLeave",
    queries
  );
  if (!attendanceList.status) {
    response.message = attendanceList.message;
    return response;
  }

  const filterInBranch = attendanceList.data.filter((d) => {
    if (branchId === "ทั้งหมด" || !branchId) {
      return d;
    } else {
      return d.branchList.includes(branchId);
    }
  });
  const getAttendanceLabel = await getFirestoreData("AttendanceSettingLabel");
  if (!getAttendanceLabel.status) {
    response.message = getAttendanceLabel.message;
    return response;
  }

  let init = [];
  filterInBranch.forEach((d) => {
    let findLabel = getAttendanceLabel.data.find((l) => {
      return l.id === d.title;
    });
    init.push({ ...d, titleLabel: findLabel?.label });
  });
  list = init;

  response.status = true;
  response.data = list;
  return response;
};
