import React from "react";
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  TextInput,
} from "react-native-web";
//service
import moment from "moment";
import { equalTo, orderByChild } from "firebase/database";
import { useDialog } from "./Popup";
import {
  updateRealtimeData,
  getRealtimeDataWithQuery,
} from "../../service/firebase";

const PopupValidKey = (props) => {
  const { onClose, onEnter } = props;
  const dialog = useDialog();

  const [pass, setPass] = React.useState("");
  async function enter() {
    if (!pass) {
      dialog.alertParent({ message: `กรุณากรอกรหัสดำเนินการ` });
      return;
    }
    const queryConstraints = [orderByChild("genKey"), equalTo(pass)];
    try {
      const result = await getRealtimeDataWithQuery(
        "RoleKey",
        queryConstraints
      );

      if (!result.status || !result.data) {
        dialog.alertParent({ message: `รหัสดำเนินการไม่ถูกต้อง` });
        return;
      }

      let data;
      Object.values(result.data).forEach((item) => {
        data = item;
      });

      const { isUse, timestamp } = data;

      if (isUse) {
        dialog.alertParent({ message: `รหัสถูกใช้งานแล้ว` });
        return;
      }

      let now = moment();
      let time = moment.unix(timestamp);

      if (time.isBefore(now)) {
        dialog.alertParent({ message: `รหัสหมดอายุ` });
        return;
      }

      const snapshotKey = Object.keys(result.data)[0];
      const res = await updateRoleKey(snapshotKey);
      console.log("RES", res);
      if (!res.status) {
        dialog.alertParent({ message: `error status : ${res.message}` });
        return;
      }
      if (onEnter) {
        onEnter(snapshotKey);
      }
    } catch (err) {
      dialog.alertParent({ message: `Error: ${err.message}` });
    }
  }

  async function updateRoleKey(key) {
    try {
      await updateRealtimeData(`RoleKey/${key}`, { isUse: true });
    } catch (error) {
      dialog.alertParent({ message: `error ${error.message}` });
      return { status: false, message: error.message };
    } finally {
      return { status: true, message: "" };
    }
  }

  return (
    <Modal visible={true} transparent={true}>
      <View
        style={{
          ...Styles.modal,
        }}
      >
        <View
          style={{
            ...Styles.content,
          }}
        >
          <Text style={{ fontSize: 18, fontWeight: "bold" }}>
            กรุณากรอกรหัสดำเนินการ
          </Text>
          <View style={{ height: 20 }} />
          <View style={{ width: "100%" }}>
            <TextBoxInput
              title={"รหัสดำเนินการ"}
              maxLength={100}
              value={pass}
              onChangeText={(text) => {
                setPass(text);
              }}
            />
          </View>

          <View style={{ height: 20 }} />
          <TouchableOpacity
            style={{
              ...Styles.button,
            }}
            onPress={() => {
              enter();
            }}
          >
            <Text style={{ fontSize: 18, fontWeight: "bold", color: "#FFF" }}>
              ยืนยัน
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{}}
            onPress={() => {
              onClose && onClose();
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 16, marginTop: 10 }}>
              ปิด
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

function TextBoxInput(props) {
  const { title, maxLength, value, editable } = props;
  const { onChangeText } = props;
  return (
    <View style={{ flex: 1, justifyContent: "center" }}>
      <Text style={{ marginBottom: 5, color: "#666" }}>{title}</Text>
      <View
        style={{
          ...Styles.textContainer,
        }}
      >
        <TextInput
          style={{
            ...Styles.textInput,
          }}
          maxLength={maxLength}
          value={value && value}
          onChangeText={(text) => {
            onChangeText && onChangeText(text);
          }}
          editable={editable && editable}
        ></TextInput>
      </View>
    </View>
  );
}

const Styles = {
  modal: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  content: {
    width: "90%",
    maxWidth: 500,
    padding: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    alignItems: "center",
  },
  textContainer: {
    width: "100%",
    height: 40,
    backgroundColor: "#FFF",
    shadowColor: "#DADADA",
    borderRadius: 10,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.6,
    shadowRadius: 5,
  },
  textInput: {
    fontSize: 16,
    flex: 1,
    margin: 10,
    outlineWidth: 0,
    color: "#333",
  },
  button: {
    width: "100%",
    height: 40,
    backgroundColor: "#4552D1",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
};

export default PopupValidKey;
